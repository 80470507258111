import React from "react";
import CountUp from "react-countup";
import TrackVisibility from "react-on-screen";
import { Layout, PortfolioNav, PortfolioHeader } from "../../components";
import locales from "../../constants";

const Seznam = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "pl";
  return (
    <Layout
      header={{
        background:
          "linear-gradient( 45deg, #800202, #B41818, #CF0606, #B41818, #800202 )",
        icons: "#ca0001",
        navClass: "seznam",
      }}
      seo={{
        title: "Seznam",
        headerTitle: "seznam",
        href: slug,
        lang: "pl",
        ogImage: require("../../assets/img/portfolio/seznam_top.jpg"),
      }}
      langSwitch={{
        langKey: "en",
        langSlug: "/en/projects/seznam/",
      }}
    >
      <PortfolioHeader name="seznam" height="82" />
      <section className="container-fluid seznam_section_2" id="info">
        <div className="row">
          <div className="col-md-6">
            <div className="inner">
              <h1>Seznam.cz</h1>
              <ul>
                <li>Kampanie zagraniczne</li>
                <li>Case Study</li>
              </ul>
            </div>
          </div>
          <div className="col-md-6">
            <div className="inner">
              <p>
                Kiedy do naszej agencji zgłosił się Klient z zapytaniem o ofertę
                kampanii Google Ads (AdWords) na Czechy, ucieszyliśmy się, bo
                lubimy poszerzać zakres naszych działań i podbijać zagraniczne
                rynki. Nie przypuszczaliśmy jednak wtedy, jak wiele ten
                przypadek wniesie do naszej pracy oraz w jak ciekawy sposób
                pozwoli poszerzyć naszą wiedzę.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid seznam_section_3">
        <div className="row">
          <div className="col-md-8 m-auto">
            <h4>Klient, potrzeba</h4>
            <p>
              Właściciel dużego sklepu internetowego z asortymentem wyposażenia
              wnętrz poprosił nas o audyt obecnej kampanii Google Ads (AdWords)
              oraz przygotowanie strategii promocji swojego biznesu e-commerce.
            </p>
            <p>
              Przeprowadzony przez nas audyt wykazał wiele niedociągnięć zarówno
              w strukturze, jak i działaniu dotychczasowej kampanii. Brakowało
              ważnej dla e-commerce kampanii produktowej, a kampanie skierowane
              na sieć wyszukiwania nie były dobrze dopracowane.
            </p>
            <p>
              Po zapoznaniu się z profilem czeskiego klienta, analizie jego
              potrzeb oraz po rozeznaniu się w architekturze sklepu
              internetowego i oferowanym asortymencie, wyszliśmy z propozycją
              całkowitej przebudowy kampanii oraz stworzyliśmy plan pracy na
              najbliższe trzy miesiące.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 m-auto">
            <h4>Przeszkody:</h4>
            <p>
              Na pierwszy rzut oka najtrudniejszym zadaniem może wydawać się
              stworzenie reklam tekstowych w języku czeskim w taki sposób, aby
              rzeczywiście odpowiadały zapytaniu użytkownika i informacjom
              zawartym na stronie docelowej. Tworząc reklamy tekstowe
              podpieraliśmy się reklamami z poprzedniej kampanii, które wymagały
              poprawek technicznych i kosmetycznych. Były jednak dobrą podstawą
              i punktem wyjścia dla obecnych, prawidłowo skonstruowanych reklam.
            </p>

            <p>
              Analiza pierwszych wyników wykazała, że pomimo zastosowania
              różnych typów dopasowania dla fraz, reklamy wyświetlają się
              użytkownikom poszukującym zbliżonych do oferowanych przez sklep
              produktów. Obserwacja wyszukiwanych przez użytkowników haseł w
              języku czeskim nie należy do najłatwiejszych zadań – pomimo wielu
              podobieństw z językiem polskim, znaczna większość słów ma jednak
              inne znaczenie. Wykluczanie nietrafnych słów kluczowych to jeden z
              podstawowych elementów optymalizacji tej kampanii.
            </p>

            <p>
              Kolejną przeszkodą, którą mieliśmy wcześniej na uwadze, okazał się
              być fakt, że jednak spora część czeskich internautów nadal
              korzysta z rodzimej wyszukiwarki – Seznam.cz. Jak podaje Digital
              News Report, 35% czeskich użytkowników Internetu jest wiernych
              Seznamowi[i]. Połączenie działań w Google i Seznamie daje nam
              możliwość dotarcia do 90% użytkowników na rynku czeskim.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 m-auto">
            <figure className="figure">
              <img
                src={require("../../assets/img/portfolio/seznam_main_1.jpg")}
                className="figure-img img-fluid"
                alt=""
              />
              <figcaption className="figure-caption mt-3">
                Ryc. 1 Seznam.cz – jak widać jest nie tylko wyszukiwarką.
              </figcaption>
            </figure>
            <p className="my-5">
              Pomimo tego, że Google powoli wchodzi na czeski rynek, to jednak
              przekonują się do niego głównie osoby młode. Naszą główną grupą
              docelową były osoby 30+, a te nadal są wierne tradycjom i chętniej
              korzystają z Seznama. Seznam, podobnie jak Google, nie jest
              wyłącznie wyszukiwarką. Daje użytkownikom wiele możliwości:
              utworzenie kont pocztowych, przegląd wiadomości, korzystanie z
              map, a także pozwala reklamodawcom na umieszczanie w nim linków
              sponsorowanych i banerów w sieci partnerskiej oraz ustawienie
              remarketingu i kampanii produktowych za pomocą narzędzia
              marketingowego Sklik. Sklik jest systemem działającym podobnie do
              Google Ads (AdWords), ale niestety w porównaniu do Google Ads nie
              posiada wielu podstawowych funkcji ułatwiających pracę przy
              tworzeniu reklam (jak chociażby możliwość kopiowania kampanii, czy
              grup reklam).
            </p>
            <figure className="figure">
              <img
                src={require("../../assets/img/portfolio/seznam_main_2.jpg")}
                className="figure-img img-fluid"
                alt=""
              />
              <figcaption className="figure-caption mt-3">
                Ryc. 2 Reklamy na Seznamie. W porównaniu do obecnych wyników
                wyszukiwania w Google, Seznam jest bardzo obłożony reklamami i
                nadal wspiera reklamy w prawej kolumnie. Dla osób
                przyzwyczajonych do stylu Google, Seznam może wydawać się nieco
                chaotyczny i nieuporządkowany.
              </figcaption>
            </figure>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8 m-auto">
            <h4>Rozwiązanie:</h4>
            <p>
              Głównym celem kampanii, jak to zazwyczaj bywa w przypadku kampanii
              dla e-commerce, był wzrost przychodów w sklepie. Jednak, by
              kampanie SEM przyniosły oczekiwane rezultaty, konieczne było
              zbudowanie nowej kampanii Google Ads (AdWords) oraz zapoznanie się
              z systemem Sklik i uruchomienie kampanii na Seznamie.
            </p>
            <p>
              W naszych działaniach skupiliśmy się przede wszystkim na
              kampaniach produktowych, których wcześniej nie było i które
              wymagały konfiguracji od podstaw: wygenerowania pliku
              produktowego, utworzenia kont Google Merchant Center oraz
              utworzenia kampanii w Google Ads i Sklik.
            </p>
            <p>
              Bieżące prace nad kampanią obejmują optymalizację stawek, reklam
              oraz przede wszystkim faktycznych zapytań użytkowników, które
              wymagają zastosowania wielu wykluczeń słów kluczowych.
            </p>

            <h4>Efekty:</h4>
            <p>
              Nasza agencja opiekuje się kampanią tego Klienta zaledwie od
              miesiąca. Mimo to możemy już wyciągnąć wnioski i zaobserwować
              ciekawe rezultaty. Bardzo dobrym posunięciem było przydzielenie
              większych budżetów reklamowych na kampanię produktową, która stała
              się głównym źródłem przychodów z kampanii. Zauważalna okazała się
              różnica w wynikach kampanii w Google oraz w Seznamie –
              zdecydowanie lepiej kampanie produktowe odbierane są przez
              użytkowników korzystających z wyszukiwarki Google. Może to mieć
              związek z wyglądem strony z wynikami wyszukiwania, na której
              zaprezentowane są reklamy produktowe. W panelu Google Ads widzimy
              również, jak poprawił się ruch ze zoptymalizowanych reklam
              tekstowych oraz wzrósł ich CTR.
            </p>

            <h4>Efekty w liczbach:</h4>

            <TrackVisibility once offset={300}>
              {({ isVisible }) => (
                <ul>
                  <li>
                    wzrost przychodów o{" "}
                    <CountUp start={0} duration={3} end={isVisible ? 74 : 0} />%
                  </li>
                  <li>
                    wzrost liczby transakcji o{" "}
                    <CountUp start={0} duration={3} end={isVisible ? 62 : 0} />%
                  </li>
                  <li>
                    wzrost współczynnika e-commerce o{" "}
                    <CountUp start={0} duration={3} end={isVisible ? 25 : 0} />%
                  </li>
                  <li>
                    wzrost średniej wartości zamówienia o{" "}
                    <CountUp start={0} duration={3} end={isVisible ? 7 : 0} />%
                  </li>
                  <li>
                    wzrost średniej ilości produktów w zamówieniu o{" "}
                    <CountUp start={0} duration={3} end={isVisible ? 74 : 0} />%
                  </li>
                </ul>
              )}
            </TrackVisibility>
            <figure className="figure">
              <img
                src={require("../../assets/img/portfolio/seznam_main_3.jpg")}
                className="figure-img img-fluid"
                alt=""
              />
              <figcaption className="figure-caption mt-3">
                FRyc. 3 Zrzut ekranu z panelu Google Analytics – porównanie
                konwersji e-commerce dla okresu naszych działań i okresu
                bezpośrednio je poprzedzającego (obejmuje ruch z Google i
                Seznam).
              </figcaption>
            </figure>

            <h4>Co dalej:</h4>
            <p>
              Nie osiadamy na laurach i planujemy dalej rozwijać kampanię
              naszego Klienta. Aby sprzedaż utrzymywała się nadal na tak wysokim
              (i jeszcze wyższym) poziomie, planujemy optymalizację obecnej
              strony docelowej. Wsparcie w najbliższych miesiącach będzie
              obejmować pomoc w wejściu sklepu na rynki w innych krajach oraz
              przygotowanie wytycznych do budowy nowej witryny e-commerce.
            </p>
            <p>
              Dla When nie ma zadań niemożliwych, a wejście na inny system
              marketingowy i zapoznanie się od podstaw z jego interfejsem i
              funkcjonowaniem było dla nas ciekawym doświadczeniem. Lubimy takie
              wyzwania!
            </p>
            <p>
              Przedstawione case study dotyczy projektu realizowanego przez
              agencję When - część Grupy ADream
            </p>
          </div>
        </div>
      </section>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};
export default Seznam;
